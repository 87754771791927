var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "fill" } },
        [
          _c("ToggleExpanded", { attrs: { refs: _vm.$refs } }),
          _c("v-divider", {
            staticClass: "mx-4",
            attrs: { vertical: "", inset: "" },
          }),
          _c("span", { staticClass: "text-center" }, [_vm._v(" Promotions ")]),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "pl-0", attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.filter ? "Press Enter to Search" : "Search Recap",
                  "prepend-inner-icon": "mdi-magnify",
                  outlined: "",
                  dense: "",
                  "background-color": "input",
                  clearable: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.filter,
                  callback: function ($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        attrs: {
          height: _vm.tableSize,
          loading: _vm.loading,
          headers: _vm.headers,
          items: _vm.promoItems,
          "items-per-page": _vm.pageSize,
          "group-by": "promoNameNum",
          color: "#fff",
          "loading-text": "Loading... Please wait",
          "fixed-header": "",
          "hide-default-footer": "",
          dense: "",
          search: _vm.filter,
        },
        on: {
          "update:items": function ($event) {
            _vm.promoItems = $event
          },
          "update:itemsPerPage": function ($event) {
            _vm.pageSize = $event
          },
          "update:items-per-page": function ($event) {
            _vm.pageSize = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "group.header",
              fn: function (ref) {
                var group = ref.group
                var headers = ref.headers
                var toggle = ref.toggle
                var isOpen = ref.isOpen
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _c(
                        "v-btn",
                        {
                          ref: group,
                          attrs: {
                            dense: "",
                            small: "",
                            icon: "",
                            "data-open": isOpen,
                          },
                          on: { click: toggle },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  isOpen ? "mdi-chevron-up" : "mdi-chevron-down"
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(group) + " "),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item.allowance",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      "$" + _vm._s(_vm.formatCurrencyDisplay(item.allowance))
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item.variance",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      "$" + _vm._s(_vm.formatCurrencyDisplay(item.variance))
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item.unitsrp",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("span", [
                    _vm._v(
                      "$" + _vm._s(_vm.formatCurrencyDisplay(item.unitsrp))
                    ),
                  ]),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", flat: "", color: "#fafafa" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", justify: "end" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { "max-width": "150px" },
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "mt-8",
                                attrs: {
                                  dense: "",
                                  items: _vm.pageSizes,
                                  label: "Items Per Page",
                                  "menu-props": {
                                    top: true,
                                    offsetY: true,
                                    maxHeight: 500,
                                  },
                                },
                                model: {
                                  value: _vm.pageSize,
                                  callback: function ($$v) {
                                    _vm.pageSize = $$v
                                  },
                                  expression: "pageSize",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disablePrevious,
                              },
                              on: { click: _vm.previous },
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                            1
                          ),
                          _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disableNext,
                              },
                              on: { click: _vm.next },
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }